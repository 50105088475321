<template>
  <div>
    <div v-if="isThereData">
    </div>
    <!-- Table -->
    <b-pagination size="sm" align="fill"
                  v-if="(getTotalItems / perPage) > 1"
                  v-model="currentPage"
                  :total-rows="getTotalItems"
                  :per-page="perPage"
                  :disabled="!getAvailableList"
                  aria-controls="notificaciones-table"
    ></b-pagination>
    <b-table
      id="notificacionesTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-function="filterFunction"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron notificaciones con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(remitente.email)="row">
        <filtered-row :value="row.item.remitente.email" :filter="filter"/>
      </template>

      <template #cell(asunto)="row">
        <filtered-row :value="row.item.asunto" :filter="filter"/>
      </template>

      <template #cell(fecha)="row">
        <filtered-row :value="row.item.fecha" :filter="filter"/>
      </template>

      <template #cell(mensaje)="row">
        <filtered-row :value="row.item.mensaje" :filter="filter"/>
      </template>

      <template #cell(archivos)="row">
        <span class="text-sm text-left w-100">
          <ul>
            <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
              <b-button variant="link" @click="onDownloadFile(archivo)">
                {{ archivo.original_name }}
              </b-button>
            </li>
          </ul>
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="12">
                <b-card
                  header="Destinatarios"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-table
                    id="destinatariosTable"
                    v-if="row.item.destinatarios.length > 0"
                    :fields="destinatariosFields"
                    :items="row.item.destinatarios"
                    striped
                    bordered
                    responsive
                    hover
                    stacked="lg"
                    show-empty
                    small
                    :per-page="destinatariosPerPage"
                    :current-page="destinatariosCurrentPage"
                  >
                    <template #cell(inversionista.rfc)="row">
                      <filtered-row :value="row.item.inversionista.rfc" :filter="filter"/>
                    </template>
                    <template #cell(inversionista.identificacion)="row">
                      <filtered-row :value="row.item.inversionista.identificacion" :filter="filter"/>
                    </template>
                    <template #cell(email)="row">
                      <filtered-row :value="row.item.email" :filter="filter"/>
                    </template>
                    <template #cell(mensaje)="row">
                      <span class="text-sm text-left w-100" :class="{ 'text-muted': row.item.is_deleted }">
                        <div v-html="row.item.mensaje"></div>
                      </span>
                    </template>
                  </b-table>
                  <b-pagination size="sm" align="fill"
                                v-if="(row.item.destinatarios.length / destinatariosPerPage) > 1"
                                v-model="destinatariosCurrentPage"
                                :total-rows="row.item.destinatarios.length"
                                :per-page="destinatariosPerPage"
                                aria-controls="notificaciones-table"
                  ></b-pagination>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-if="row.item.archivos.length > 0">
              <b-col cols="12" md="12">
                <b-card
                  header="Archivos"
                  no-body
                  border-variant="success"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <span class="text-sm text-left w-100">
                    <ul>
                      <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
                        <b-button variant="link" @click="onDownloadFile(archivo)">
                          {{ archivo.original_name }}
                        </b-button>
                      </li>
                    </ul>
                  </span>
                </b-card>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '@/components/Common/Table/FilteredRow'

const API_MODULE = 'notificacionesModule'

export default {
  name: 'NotificacionesList',

  components: {
    FilteredRow
  },

  props: {
    filter: {
      required: true
    }
  },

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'remitente.email', label: 'Remitente', sortable: true },
        { key: 'asunto', label: 'Asunto' },
        { key: 'fecha', label: 'Fecha' },
        { key: 'mensaje', label: 'Mensaje' },
        { key: 'show_details', label: 'Detalles' }
      ],

      perPage: 10,

      currentPage: 1,

      destinatariosFields: [
        { key: 'inversionista.rfc', label: 'RFC' },
        { key: 'email', label: 'Correo electrónico' },
        { key: 'inversionista.identificacion', label: 'Inversionista' },
        { key: 'mensaje', label: 'Mensaje' }
      ],

      destinatariosPerPage: 5,

      destinatariosCurrentPage: 1
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ]),
    ...mapGetters('userModule', [
      'isAuthUserOrCloneUser'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Notificaciones')
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return row.remitente.email.toLowerCase().includes(filter.toLowerCase()) ||
        row.asunto.toLowerCase().includes(filter.toLowerCase()) ||
        row.fecha.toLowerCase().includes(filter.toLowerCase()) ||
        row.mensaje.toLowerCase().includes(filter.toLowerCase()) ||
        row.destinatarios.some((element, index) =>
          (element && element.email && element.email.toLowerCase().includes(filter.toLowerCase())) ||
          (element && element.inversionista && element.inversionista.rfc && element.inversionista.rfc.toLowerCase().includes(filter.toLowerCase())) ||
          (element && element.inversionista && element.inversionista.identificacion && element.inversionista.identificacion.toLowerCase().includes(filter.toLowerCase()))
        )
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    async onDownloadFile (archivo) {
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.original_name)

      this.$notify({ message: `Descarga de archivo ${archivo.original_name}.` })
    }
  }
}
</script>
