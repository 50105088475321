<template>
  <base-view title="Notificaciones" icon="bell">
    <div>
      <check-authorization :requiresAuthorizations="['indice notificaciones']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Historial de Notificaciones

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <!-- Search -->
              <search-bar
                placeholder="Buscar por remitente, asunto, fecha, contenido del mensaje o inversionista"
                @filter="(filter) => this.filter = filter"></search-bar>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getResource(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <notificaciones-list @on-got-item="showForm = true" :filter="filter"></notificaciones-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SearchBar from '@/components/Common/Views/SearchBar'
import NotificacionesList from '@/components/Notificaciones/NotificacionesList'

export default {
  name: 'NotificacionesMainView',

  components: {
    NotificacionesList,
    SearchBar
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      filter: null
    }
  },

  computed: mapGetters('notificacionesModule', ['isEditingResource']),

  methods: {
    ...mapActions('notificacionesModule', ['getResource'])
  }
}
</script>
